<template>
    <div class="templateSingleOptionCard  template-btn" :style="{ 'padding-top': getTemplatePaddingTop }"
        :class="{ 'templateSingleOptionCard-4': templateData && templateData.languageConfig[$language].optionList.length == 4 }">

        <Head></Head>
        <div class="animation" v-if="animationLoading && templateData">
            <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
            <div class="content">
                <div class="option-group">
                    <div class="option-item"
                        :class="{ active: item.originName == templateValue, 'shrink-grow-effect': activeIndex === index }"
                        @click="selectOption(item.originName, true, item.option, index), setOptionClickAnimation(index)"
                        v-for="(item, index) in templateData.languageConfig[$language].optionList" :key="index">
                        <div class="flex tc text_2">
                            <div class="img-warp"><img class="img"
                                    :src="require(`@img/template/${templateData.imageList[index]}`)" width="137" alt="">
                            </div>
                            <div class="text"> {{ item.option }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";
export default {
    name: "templateSingleOptionCard",
    mixins: [mixinTemplate],
    components: {
        Head
    }

}
</script>
<style scoped lang="scss">
.templateSingleOptionCard {

    .content {
        margin-top: 32px;

        .option-group {
            padding: 0 8px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .option-item {
                margin: 0 8px;
                margin-bottom: 12px;
                padding: 0;
                width: calc(33.333% - 16px);
                background-color: var(--theme_03);
                border-radius: 16px;
                box-shadow: 0px 15px 45px 0px rgba(0, 0, 0, 0.02);
                overflow-wrap: break-word;
                text-align: center;
                height: auto !important;

                .img-warp {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    position: relative;
                    margin-top: -2px;
                }

                .img {
                    width: 100%;
                    transition: transform .6s;
                }

                .img:hover {
                    transform: scale(1.1);
                }

                .text {
                    width: 100%;
                    text-align: center;
                    margin: 8px 0;
                    margin-top: 2px;
                    line-height: 20px;
                }

                /deep/.div__icon {
                    display: none !important;
                }
            }
        }
    }


}

.templateSingleOptionCard-4 .content .option-group .option-item {
    width: calc(25% - 16px);
}

@media (max-width: 576px) {
    .templateSingleOptionCard {
        .content {
            margin-top: 24px;

            .option-group {
                padding: 0;

                .option-item {
                    margin: 0;
                    margin-bottom: 12px;
                    width: calc(50% - 8px);
                }
            }
        }
    }
}
</style>