<template>
  <div class="templateAgeGroup " :style="{ 'padding-top': getTemplatePaddingTop }">

    <Head></Head>
    <div class="animation" v-if="animationLoading && templateData">
      <div class="heading_1 title tc"
        v-html="$utils.redirectUrlKeyword(templateData.languageConfig[$language].mainTitle)"></div>
      <div class="title_2 sub-title tc" v-html="templateData.languageConfig[$language].subTitle"></div>
      <div class="text_1 tc" v-html="templateData.languageConfig[$language].tips"></div>
      <div class="option-group flex justify-center">
        <div class="option-item" @click="selectAge(item.originName, index)"
          :class="{ right: index % 2 != 0, 'shrink-grow-effect': activeIndex === index }"
          v-for="(item, index) in templateData.languageConfig[$language].optionList">
          <div class="img-warp">
            <div class="option tc text_1">
              <span v-html="item.option"></span>
              <van-icon class="icon" name="arrow" color="#fff" />
            </div>
            <div class="option-img-warp">
              <div class="option-img-border">
                <img class="option-img" :src="require(`@img/template/${templateData.imageList[index]}`)" alt="">
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="text_3 tc pointer">
        <span v-html="templateData.languageConfig[$language].mainText"></span>
        <span @click="$utils.routerPush('/policy-page?type=termsOfService')" class="link-item">{{
          templateData.languageConfig[$language].linkText1 }}</span>&
        <span @click="$utils.routerPush('/policy-page?type=privacyPolicy')" class="link-item">{{
          templateData.languageConfig[$language].linkText2 }}</span>
      </div>
    </div>
  </div>
</template>
<script>

import mixinTemplate from "@/mixin/index";
import Head from "./components/head.vue"
import { setGtag } from "@/utils/ga4"
import { setFbqTrackCustom } from "@/utils/facebookPixel"
import { setmMxpanelValue } from "@/utils/mixpanel";
import {
  addFirestoreData,
  signOutWithEmail,
  createAnonymousAccount
} from "@/utils/firebase";
import { Toast } from "vant";
export default {
  name: "templateAgeGroup",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
  data() {
    return {
      toast: null
    }
  },

  mounted() {
    signOutWithEmail()//退出匿名账号
    setGtag("Web Onboarding Started")
    this.setmMxpanelValue(this.sdkConfig.mixpanelConfig.onboardingEventKey, 'Started');
    setFbqTrackCustom('Onboarding Started');
  },
  methods: {
    selectAge(item, index) {
      this.setOptionClickAnimation(index);
      this.getAnonymousAccount(item)//创建匿名账号
    },
    async getAnonymousAccount(item) {
      this.toast = Toast.loading({
        duration: 0,
      });
      let uid = await createAnonymousAccount();

      //存储uid
      if (uid) {
        this.$store.commit("updateOnBoardingRecord", {
          key: "uid",
          value: uid,
          noSet: true, //不需要存入数据
        });
        if (this.$store.state.onBoardingRecordInfo.uid) {
          let onBoardingRecordInfo = this.$store.state.onBoardingRecordInfo;
          onBoardingRecordInfo.workoutSpend = '15 - 20 minutes';
          await addFirestoreData(uid, onBoardingRecordInfo);//添加firebae数据
          this.toast.clear();
          this.selectOption(item);
        } else {
          this.getAnonymousAccount();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.templateAgeGroup {

  .sub-title {
    margin: 10px 0;
  }

  .link-item {
    margin: 0 5px;
    text-decoration: underline;
    color: var(--theme_01);
  }

  .option-group {
    padding: 10px 0;
    flex-wrap: nowrap;



    .option-item {
      padding: 0 !important;
      margin: 2% 20px;
      width: auto !important;
      cursor: pointer;
      background: none !important;
      border: none !important;
      box-sizing: border-box;
      text-align: right;

      .img-warp {
        display: inline-block;
        position: relative;
        width: 100%;
        max-width: 240px;
        max-height: 240px;
        overflow: hidden;

        .option-img-warp {
          width: 100%;
          height: 100%;




          .option-img-border {
            margin-top: 40px;
            width: 100%;
            height: 100%;
            background: #EDEFF5;
            border-radius: 50%;
            border: 2px solid #E4E4E4;
            position: relative;
            z-index: 0;

            .option-img {
              position: relative;
              top: -25px;
              z-index: 1;
              width: 100%;
              height: 100%;
              object-fit: contain;
              transform: scale(1);
              transition: transform .6s;
            }
          }

          .option-img:hover {
            transform: scale(1.05);
          }
        }


        .option {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 51px;
          line-height: 51px;
          min-height: 51px !important;
          color: var(--theme_03);
          background: var(--mainColor);
          border-radius: 999px;
          z-index: 999;

          .icon {
            font-weight: bold;
            margin-left: 5px;
          }
        }
      }
    }

    .right {
      text-align: left;
    }
  }
}

@media (max-width: 1024px) {
  .templateAgeGroup {
    .option-group {
      flex-wrap: wrap;

      .option-item {
        margin: 0;
        padding: 0 20px !important;
        width: 46% !important;

        .img-warp {
          width: 220px;

          .option {
            height: 44px;
            line-height: 44px;
            min-height: 44px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .templateAgeGroup {
    .option-group {
      justify-content: space-between;

      .option-item {
        width: 48%;
        padding: 0 !important;

        .img-warp {
          width: 100%;

          .option {
            height: 44px;
            line-height: 44px;
            min-height: 44px !important;
          }
        }
      }
    }
  }
}
</style>
