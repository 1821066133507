import developmentConfig from "./development.config";
import productionConfig from "./production.config";

const NODE_ENV = "production"; // development:开发环境 test：测试环境 production：生产环境
let config = NODE_ENV == "development" ? developmentConfig : productionConfig;
let sdkConfig = {
    statsigId: 'client-c6tz0KmFVQgF9sOD09T45x8duPpfd0Rnttgc1VfzXPh',//录屏测试工具id
    weightType: 0,// 存入weight的值类型 ：1:lbs  0:kg
    heightCmUnitNumber: 1,  //单位：定义cm单位为1
    weightKgUnitNumber: 1, //单位：定义kg单位1
    websiteName: "orkout for Women", //网站名称
    languageList: ["en"], //多语言白名单
    urlKeywordWhitelist: ["30200"], //url关键字白名单
    defaultUrlKeyword: "30200", //默认关键字
    flowName: "somatic",//flow名称
    progectName: "oog104", //接口项目名称
    logoMarginRight: '-5px',//logo图片与文本距离
    facebookPixelWhiteList: ["378457105095189", "507274421994198"], //facebook pixel白名单
    defaultFacebookPixelId: "378457105095189",//默认facebook pixel id
    //系统接口对应版本号
    systemApiVersion: {
        sendTempMail: "v1",
        sendContactUsEmail: "v1",
        getSubscriptionList: "v1",
        getPriceList: "v2",
        createCheckoutSessionEmbedded: "v2",
        createCheckoutPaypalSession: 'v1',
        synchPaypalSubscription: 'v1',
        getSubscriptionInfo: "v1",
        getPaypalSubscriptionInfo: 'v1',
        cancelSubscription: "v1",
        transferSubscription: "v1",
        resumeSubscriptionInfo: "v1",
        mergeMixpanelData: "v1",
    },
    //summary存入选项展示数据---(yogaLevelScreen ，eventScreen 固定需要)
    summaryOptionList: [
        "yogaLevelScreen",
        "currentBodyTypeScreen",
        "activityLevelScreen",
        "eventScreen",
    ],
    //需要隐藏返回键的模版别名
    onboardingHiddenBackScreenNameList: [
        "ageGroupScreen",
        "welcomeScreen",
        "generationScreen",
        "planReadyScreen",
    ],
    //summary模版页面展示的选项
    summaryBodyInfoList: [
        {
            type: "YogaLevel",
            value: "yogaLevelScreen",
        },
        {
            type: "BodyType",
            value: "currentBodyTypeScreen",
        },
        {
            type: "ActivityLevel",
            value: "activityLevelScreen",
        },
    ],
    //onborrding加载时mixpanel需要记录的模版数组
    onboardingViewScreenNameList: [
        "generationScreen",
        "planReadyScreen",
        "introScreen01",
        "introScreen02",
        "introScreen03",
        "introScreen04",
        "introScreen05",
        "introScreen06",
        "summaryScreen",
        "generationScreen",
        "welcomeScreen",
        "predictionScreen",
        "promotionScreen",
    ],
    //onborrding点击提交时mixpanel不需要记录的模版数组
    onboardingClickNoNeedScreenNameList: [],
    //onborrding点击提交时mixpanel需要记录用户信息的模版数组
    onboardingUserInfoScreenNameList: [
        {
            screenName: "emailScreen",
            key: "$email",
            type: 1, //1:原类型，2转数组
        },
        {
            screenName: "ageGroupScreen",
            key: "user_age_group",
            type: 1, //1:原类型，2转数组
        },
        {
            screenName: "yogaLevelScreen",
            key: "user_yoga_level",
            type: 2, //1:原类型，2转数组
        },
        {
            screenName: "preferredPositionScreen",
            key: "user_position",
            type: 2,
        },
        {
            screenName: "restrictionScreen",
            key: "user_restrictions",
            type: 2,
        },
        {
            screenName: "bodyPartScreen",
            key: "user_focus_areas",
            type: 2,
        },
        {
            screenName: "focusScreen",
            key: "user_goal",
            type: 2,
        },
        {
            screenName: "ageScreen",
            key: "user_age",
            type: 1,
        },
        {
            screenName: "heightScreen",
            key: "user_height",
            type: 1,
        },
        {
            screenName: "currentWeightScreen",
            key: "user_weight",
            type: 1,
        },
        {
            screenName: "energyLevelScreen",
            key: "user_active",
            type: 2,
        },
    ],
    onBoardingKey: {
        purposeScreen: "motivates",
        activityLevelScreen: "activeLevel",
        activitiesScreen: "interestedActivities",
        ageGroupScreen: "ageRange",
        ageScreen: "ageNum",
        bodyPartScreen: "bodyPartScreen",
        breathConditionScreen: "breathConditionScreen",
        // currentBodyTypeScreen: "bodyType",
        currentWeightScreen: "currentWeightNum",
        // dreamBodyTypeScreen: "desiredBodyType",
        dietScreen: "dietScreen",
        emailScreen: "email",
        energyLevelScreen: "energyLevelScreen",
        eventDateScreen: "eventDateScreen",
        eventScreen: "eventScreen",
        familiarityScreen: "familiarityScreen",
        flexibiltyScreen: "flexibiltyScreen",
        focusScreen: "focusAreas",
        fitnessLevelScreen: "fitnessLevel",
        heightScreen: "heightNum",
        lastHappyBodyImageScreen: "lastTime",
        preferredPositionScreen: "activity",
        restrictionScreen: "exercises",
        sleepHourScreen: "sleepHourScreen",
        targetWeightScreen: "goalWeightNum",
        walkingDurationScreen: "walkingDurationScreen",
        waterDrinkScreen: "waterDrinkScreen",
        yogaLevelScreen: "yogaLevelScreen",
        uid: "uid",
        name: "name",
        heightUnit: "heightUnit",
        weightUnit: "weightUnit",
        workoutSpend: "workoutSpend",
        sessionId: "sessionId",
        eventDateSkip: "eventDateSkip",
    },
    // 字段对照参考表
    // onBoardingKey:{
    //     "userProperty": {"user_source": userSource},
    //     "ageRange": ageRange,
    //     "heightUnit": heightUnit == 1 ? 0 : 1,
    //     "height": heightNum,
    //     "weightUnit": weightUnit == 1 ? 0 : 1,
    //     "weight": currentWeightNum,
    //     "goalWeight": goalWeightNum,
    //     "age": ageNum,
    //     "workoutSpend": ageNum < 65 ? "10 - 15 minutes" : "5 - 10 minutes",
    //     "email": email,
    //     "name": name,
    //     "familiarityScreen": familiarityScreen,
    //     "activity": preferredPositionScreen,
    //     "currentBodyTypeScreen": currentBodyTypeScreen,
    //     "dreamBodyTypeScreen": dreamBodyTypeScreen,
    //     "lastHappyBodyImageScreen": lastHappyBodyImageScreen,
    //     "activityLevelScreen": activityLevelScreen,
    //     "walkingDurationScreen": walkingDurationScreen,
    //     "breathConditionScreen": breathConditionScreen,
    //     "yogaLevelScreen": yogaLevelScreen,
    //     "flexibilityScreen": flexibilityScreen,
    //     "energyLevelScreen": energyLevelScreen,
    //     "sleepHourScreen": sleepHourScreen,
    //     "waterDrinkScreen": waterDrinkScreen,
    //     "dietScreen": dietScreen,
    //     "eventScreen": eventScreen,
    //     "goals": achievementScreen,
    //     "bodyPartScreen": bodyPartScreen,
    //     "exercises": restrictionScreen,
    //     "focusScreen": focusScreen,
    //     "eventDateScreen": eventDateScreen,
    //     "chroincs":[],
    //     "during":"",
    //     "interesteds":[],
    //     "gender":""
    //   };
    //用户来源 web/app
    // late String userSource;
    // //年龄范围
    // late String ageRange;
    // //身高 单位 1:英寸  0:厘米
    // late int heightUnit;
    // //身高 厘米
    // late double heightNum;
    // //体重 单位 1:lb  0:kg
    // late int weightUnit;
    // //当前体重 公斤
    // late double currentWeightNum;
    // //目标体重 公斤
    // late double goalWeightNum;
    // //年龄
    // late int ageNum;
    // //邮箱
    // late String email;
    // //名称
    // late String name;
};
sdkConfig = Object.assign(config, sdkConfig);
export default sdkConfig;
